import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import { 
  VALID_CAMPAIGN_TYPES, 
  CAMPAIGN_MECHANICS_PAYLOAD_VALIDATION, 
  PERC_OFF_EVERYTHING,
  PERC_OFF_SPECIFIC_ITEMS,
  SPEND_AND_GET_PERC_OFF,
  SPEND_AND_GET_VAL_OFF,
  SPEND_AND_GET_GIFT,
  FREE_SHIPPING,
  FREE_EXPRESS_SHIPPING,
  CONTENT,
  PERC_OFF,
  ITEMS,
  SPEND_THRESHOLD,
  VALUE_OFF,
  VALUE_OFF_EVERYTHING,
  VALUE_OFF_SPECIFIC_ITEMS,
  GIFT,
  GIFT_VALUE,
  GIFT_CARD_VALUE,
  PRICE_POINT,
  INCENTIVE,
  INCENTIVE_EXPIRY
} from '@loypal/lib-growth';
import InputAdornment from '@material-ui/core/InputAdornment';
import capitalize from 'lodash/capitalize'
import theme from '../../../assets/css/theme';
import { Select } from '../../../components/Form';
import { InputLabel, TextInput } from '../components';

interface IMetaData {
  [key: string]: any
}

interface ICampaignMechanicsProps {
  campaignMechanic: string | null;
  metaData: IMetaData;
  setCampaignMechanic(val: string): void;
  setMetaData(val: any): void;
}

interface IInputProps {
  width: string
}

interface IAdditionalLabelProps {
  startAdornment?: any,
  endAdornment?: any,
}

const BOUNCEBACK_TYPES = [
  PERC_OFF_EVERYTHING,
  PERC_OFF_SPECIFIC_ITEMS,
  SPEND_AND_GET_PERC_OFF,
  SPEND_AND_GET_VAL_OFF,
  SPEND_AND_GET_GIFT,
  FREE_SHIPPING,
  FREE_EXPRESS_SHIPPING,
  VALUE_OFF_EVERYTHING,
  VALUE_OFF_SPECIFIC_ITEMS
]

const INPUT_FORMATS = {
  [CONTENT]: {
    type: 'string',
    width: '200px'
  },
  [PERC_OFF]: {
    type: 'number',
    width: '80px',
    endAdornment: '%'
  },
  [ITEMS]: {
    type: 'string',
    width: '200px'
  },
  [SPEND_THRESHOLD]: {
    type: 'number',
    width: '80px',
    startAdornment: '$'
  },
  [VALUE_OFF]: {
    type: 'number',
    width: '80px',
    startAdornment: '$'
  },
  [GIFT]: {
    type: 'string',
    width: '200px',
  },
  [GIFT_VALUE]: {
    type: 'number',
    width: '80px',
    startAdornment: '$'
  },
  [GIFT_CARD_VALUE]: {
    type: 'number',
    width: '80px',
    startAdornment: '$'
  },
  [PRICE_POINT]: {
    type: 'number',
    width: '80px',
    startAdornment: '$'
  },
  [INCENTIVE_EXPIRY]: {
    type: 'number',
    width: '80px'
  }
}

const DEFAULT_INPUT_FORMAT = {
  type: 'string',
  width: '200px'
}

const BouncebackInfo = ({ inputLabel, onChange }: { inputLabel: string, onChange(key: string, value: any): void }) => {
  const [incentiveType, setIncentiveType] = useState<string | null>();

  const handleChange = (event: any) => {
    setIncentiveType(event.target.value as string);
  };

  const requiredInfo: string[] = incentiveType ? CAMPAIGN_MECHANICS_PAYLOAD_VALIDATION[incentiveType].additional_data_required : []
  const optionalInfo: string[] = incentiveType ? CAMPAIGN_MECHANICS_PAYLOAD_VALIDATION[incentiveType].additional_data_optional : []

  return (
    <>
      <CampaignInputConatiner>
      <InputLabel>{inputLabel}</InputLabel>
        <InputContainer>
          <SelectStyled 
            options={BOUNCEBACK_TYPES.map((campaignType: string) => ({label: capitalize(campaignType), value: campaignType}))} 
            value={incentiveType} 
            onChange={handleChange} 
            MenuProps={{
              style: {
                maxHeight: 300,
              },
            }}
          />
        </InputContainer>
      </CampaignInputConatiner>
      <BouncebackInfoContainer>
        {[...requiredInfo, ...optionalInfo].map((label: string) => <InfoInput key={label} label={label} onChange={onChange} />)}
      </BouncebackInfoContainer>
    </>
  )
}

const InfoInput = ({ label, onChange }: { label: string, onChange(key: string, value: any): void }) => {
  const inputFormat = INPUT_FORMATS[label] || DEFAULT_INPUT_FORMAT
  const inputLabel = capitalize(label.replace(/_/g, " "))

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(label, event.currentTarget.value)
  }

  if (label === INCENTIVE) {
    return <BouncebackInfo inputLabel={inputLabel} onChange={onChange}/>
  }

  const inputProps: IAdditionalLabelProps = {}

  if (inputFormat.startAdornment) {
    inputProps.startAdornment = <AdornmentStyled position="start">{inputFormat.startAdornment}</AdornmentStyled>
  }

  if (inputFormat.endAdornment) {
    inputProps.endAdornment = <AdornmentStyled position="end">{inputFormat.endAdornment}</AdornmentStyled>
  }

  return (
    <CampaignInputConatiner>
    <InputLabel>{inputLabel}</InputLabel>
      <InputContainer>
        <TextInputStyled
          key={label}
          size='small'
          type={inputFormat.type}
          variant="outlined"
          width={inputFormat.width}
          onChange={handleOnChange}
          InputLabelProps={{ style: {fontSize: 14} }}
          InputProps={inputProps}
        />
      </InputContainer>
    </CampaignInputConatiner>
  )
}

export const CampaignMechanic = ({
  campaignMechanic,
  setCampaignMechanic,
  metaData,
  setMetaData
}: ICampaignMechanicsProps) => {
  const updateMetaData = (key: string, value: any) => {
    setMetaData({
      ...metaData,
      [key]: value
    })
  }


  const clearMetaData = () => {
    setMetaData({})
  }

  const handleChange = (event: any) => {
    setCampaignMechanic(event.target.value as string);
    clearMetaData();
  };

  const [requiredInfo, optionalInfo] = useMemo(() => {
    if (!campaignMechanic) {
      return [[], []]
    }

    return [
      CAMPAIGN_MECHANICS_PAYLOAD_VALIDATION[campaignMechanic].additional_data_required,
      CAMPAIGN_MECHANICS_PAYLOAD_VALIDATION[campaignMechanic].additional_data_optional
    ]
  }, [campaignMechanic])

  return (
    <>
      <CampaignInputConatiner>
        <InputLabel>Campaign Mechanic</InputLabel>
        <InputContainer>
          <SelectStyled 
            options={VALID_CAMPAIGN_TYPES.map((campaignType: string) => ({label: capitalize(campaignType), value: campaignType}))} 
            value={campaignMechanic} 
            onChange={handleChange} 
            MenuProps={{
              style: {
                maxHeight: 300,
              },
            }}
          />
        </InputContainer>
      </CampaignInputConatiner>
      {[...requiredInfo, ...optionalInfo].map((label: string) => <InfoInput key={label} label={label} onChange={updateMetaData} />)}
    </>
  )
}

const InputContainer = styled.div`
  width: 180px;
  min-height: 40px;
  @media (max-width: 660px) and (min-width: 420px) {
    margin-top: 15px;
    margin-left: 0px;
  }
`

const SelectStyled = styled(Select)`
  && {
      & .MuiInputBase-input {
        font-family: ${theme.ffSansProRegular};
        font-size: 0.8125rem;
        color: ${theme.colorDarkGray}
      }
    }
  }
`

const CampaignInputConatiner = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 20px;
  @media (max-width: 660px) and (min-width: 420px) {
    flex-direction: column;
  }
`

const TextInputStyled = styled(TextInput)<IInputProps>`
&& {
  & > .MuiInputBase-root.MuiInputBase-formControl {
    min-width: auto;
    width: ${props => props.width};

    & .MuiInputBase-input.MuiOutlinedInput-input {
      -moz-appearance: textfield;
    }

    & .MuiInputBase-input.MuiOutlinedInput-input::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
      }

    & .MuiInputBase-input.MuiOutlinedInput-input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
  }
}
`

const AdornmentStyled = styled(InputAdornment)`
  && {
    padding: 0px 6px 0px 2px;

    .MuiTypography-root {
      font-size: 0.8125rem;
    }
  }
`

const BouncebackInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space between;
`